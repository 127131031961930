<template>
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="card">
        <div class="card-body">
          <b-card v-if="info && info.tipo != 4">
            <b-tabs content-class="mt-3" justified>
              <b-tab title="Datos Generales" active>
                <datos-generales />
              </b-tab>
              <b-tab title="Contacto de Ventas"
                ><datos-contactos-ventas
              /></b-tab>
              <b-tab title="Contacto de Operaciones"
                ><datos-contactos-operaciones
              /></b-tab>
              <b-tab title="Contacto de Administración"
                ><datos-contactos-administracion
              /></b-tab>
              <b-tab title="Datos Bancarios"><datos-bancarios /></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatosContactosVentas from "./DatosContactosVentas.vue";
import DatosBancarios from "./DatosBancarios.vue";
import useDatos from "../composables/useDatos";
import { watchEffect } from "vue";
import DatosGenerales from "./DatosGenerales.vue";
import DatosContactosOperaciones from "./DatosContactosOperaciones.vue";
import DatosContactosAdministracion from "./DatosContactosAdministracion.vue";
export default {
  components: {
    DatosContactosVentas,
    DatosBancarios,
    DatosGenerales,
    DatosContactosOperaciones,
    DatosContactosAdministracion,
  },
  setup() {
    const { getInfo, info, getCatalogos } = useDatos();
    getInfo();
    getCatalogos();
    watchEffect(() => info.value);
    return { info };
  },
};
</script>

<style lang="scss" scoped>
</style>
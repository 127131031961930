<template>
  <div class="row mt-5">
    <div class="col-md-12 mt-3">
      <h6>Contactos Registrados</h6>
      <hr />
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellidos</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Celular</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="contactosList.length <= 0">
            <td colspan="6">No se han registrado contactos</td>
          </tr>
          <tr v-else v-for="row in contactosList" :key="row.id">
            <td>{{ row.nombre }}</td>
            <td>{{ row.apellido }}</td>
            <td>{{ row.correo }}</td>
            <td>{{ row.telefono + " " + row.extension }}</td>
            <td>{{ row.celular }}</td>
            <td nowrap>
              <b-button
                v-b-modal="'formaContacto3'"
                class="btn btn-primary btn-sm btn-table"
                @click="
                  () => {
                    handleEditar(row);
                  }
                "
                ><font-awesome-icon icon="edit"
              /></b-button>

              <button
                class="btn btn-danger btn-sm btn-table mx-1"
                type="button"
                @click="
                  () => {
                    handleDelete(row.id);
                  }
                "
              >
                <font-awesome-icon icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-12 mt-3">
      <button class="btn btn-twitter btn-sm" @click="openFormContacto">
        Agregar Contacto
      </button>
    </div>
  </div>

  <b-modal
    title="Agregar Contacto"
    id="formaContacto3"
    ok-title="Guardar"
    cancel-title="Cerrar"
    ok-only
    v-model="formRef3"
    @ok="handleSaveContacto"
  >
    <div class="row">
      <div class="col-md-12">
        <label for="nombre">Nombre *</label>
        <input
          type="text"
          name="nombre"
          id="nombre"
          class="form-control form-control-sm"
          v-model="dataContacto.nombre"
        />
      </div>

      <div class="col-md-12 mt-2">
        <label for="apellido">Apellido *</label>
        <input
          type="text"
          name="apellido"
          id="apellido"
          class="form-control form-control-sm"
          v-model="dataContacto.apellido"
        />
      </div>

      <div class="col-md-12 mt-2">
        <label for="correo">Correo Electrónico *</label>
        <input
          type="email"
          name="correo"
          id="correo"
          class="form-control form-control-sm"
          v-model="dataContacto.correo"
        />
      </div>

      <div class="col-md-8 mt-2">
        <label for="telefono">Telefono *</label>
        <input
          type="text"
          name="telefono"
          id="telefono"
          class="form-control form-control-sm"
          v-model="dataContacto.telefono"
        />
      </div>

      <div class="col-md-4 mt-2">
        <label for="ext">Ext.</label>
        <input
          type="text"
          name="ext"
          id="ext"
          class="form-control form-control-sm"
          v-model="dataContacto.extension"
        />
      </div>

      <div class="col-md-12 mt-2">
        <label for="celular">Celular *</label>
        <input
          type="text"
          name="celular"
          id="celular"
          class="form-control form-control-sm"
          v-model="dataContacto.celular"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch, watchEffect } from "vue";
import useDatos from "../composables/useDatos";
import Swal from "sweetalert2";
export default {
  setup() {
    const {
      dataContactoAdministracionIni,
      setContacto,
      administracion,
      setEliminarContacto,
    } = useDatos();
    const contactosList = ref([]);
    const dataContacto = ref(dataContactoAdministracionIni);
    const formRef3 = ref(false);
    contactosList.value = administracion.value;

    const handleCloseForma = () => {};

    const handleEditar = (item) => {
      dataContacto.value = { ...dataContacto.value, ...item };
    };

    const handleSaveContacto = async () => {
      let validado = true;
      Object.keys(dataContacto.value).forEach((clave) => {
        if (
          clave != "tipo_id" &&
          clave != "extension" &&
          clave != "id" &&
          dataContacto.value[clave].length <= 0
        ) {
          validado = false;
        }
      });

      if (!validado) {
        Swal.fire(
          "Atención",
          "Favor de ingresar los campos obligatorios (*)",
          "warning"
        );
      } else {
        const result = await setContacto(dataContacto.value);
        if (result.ok) {
          formRef3.value = false;
          dataContacto.value = dataContactoAdministracionIni;

          Swal.fire(
            "Guardado",
            "Se ha guardado el contacto correctamente",
            "success"
          );
        } else {
          Swal.fire("Error", result.message, "error");
        }
      }
    };

    const handleDelete = async (id) => {
      Swal.fire({
        title: "Confirmar",
        text: "Se eliminará el contacto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await setEliminarContacto(id);
          if (result.ok) {
            //Swal.fire("Eliminado!", "Se ha eliminado el contacto.", "success");
          }
        }
      });
    };

    watch(
      () => administracion.value,
      () => {
        contactosList.value = administracion.value;
      }
    );
    watchEffect(() => dataContacto.value);

    return {
      contactosList,
      formRef3,
      dataContacto,
      handleEditar,
      handleCloseForma,
      handleDelete,
      handleSaveContacto,
      openFormContacto: () => {
        formRef3.value = true;
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
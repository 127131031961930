<template>
  <form @submit.prevent="handleGuardar">
    <div class="row p-3">
      <div class="col-md-12 mt-5"></div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="razon_social">Razón Social</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="razon_social"
            v-model="dataForm.razonSocial"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="nombre_comercial">Nombre Comercial</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="nombre_comercial"
            v-model="dataForm.nombre_comercial"
            required
          />
        </div>
      </div>

      <div class="col-md-12 mt-3"></div>

      <div class="col-md-3" v-if="info && info.tipo == 3">
        <div class="form-group">
          <label for="tax_id">TAX_ID</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="dataForm.tax_id"
            id="tax_id"
            required
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="nacionalidad">Nacionalidad de la razón social</label>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="dataForm.nacionalidad"
            id="nacionalidad"
            required
          />
        </div>
      </div>

      <div class="col-md-3" v-if="info && info.tipo != 3">
        <div class="form-group">
          <label for="rfc">R.F.C</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="rfc"
            v-model="dataForm.rfc"
            required
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="telefono">Teléfono</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="telefono"
            v-model="dataForm.telefono"
            required
          />
        </div>
      </div>

      <div class="col-md-12 mt-5">
        Domicilio Fiscal
        <hr />
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="calle">Calle</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="calle"
            v-model="dataForm.domicilio.calle"
            required
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="colonia">Colonia</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="colonia"
            v-model="dataForm.domicilio.colonia"
            required
          />
        </div>
      </div>
      <div class="col-md-2 mt-5">
        <div class="form-group">
          <label for="estado">Estado</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="estado"
            v-model="dataForm.domicilio.estado"
            required
          />
        </div>
      </div>
      <div class="col-md-2 mt-5">
        <div class="form-group">
          <label for="municipio">Municipio</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="municipio"
            v-model="dataForm.domicilio.municipio"
            required
          />
        </div>
      </div>
      <div class="col-md-2 mt-5">
        <div class="form-group">
          <label for="cp">Código Postal</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="cp"
            v-model="dataForm.domicilio.cp"
            required
          />
        </div>
      </div>
      <template v-if="info && (info.tipo == 2 || info.tipo == 3)">
        <div class="col-md-12 mt-5">
          Representante Legal
          <hr />
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_nombre">Nombre</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.nombre"
              required
            />
          </div>
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_apellido">Apellido</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.apellido"
              required
            />
          </div>
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_correo">Correo</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.correo"
              required
            />
          </div>
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_telefono">Telefono</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.telefono"
              required
            />
          </div>
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_extension">Ext.</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.extension"
              required
            />
          </div>
        </div>

        <div class="col-md-2 mt-5">
          <div class="form-group">
            <label for="representante_celular">Celular</label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="cp"
              v-model="dataForm.representante.celular"
              required
            />
          </div>
        </div>
      </template>
      <div class="col-md-12 mt-5">
        Caracteristicas del proveedor
        <hr />
      </div>

      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_tipo">Tipo de proveedor</label>
          <Select2
            id="c_tipo"
            v-model="dataForm.caracteristicas.tipo_id"
            :options="tipos"
            :settings="{ placeholder: ':: Seleccionar :' }"
            @select="() => {}"
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_servicio">¿Que servicios proporciona?</label>
          <Select2
            id="c_servicio"
            v-model="dataForm.caracteristicas.servicios"
            :options="servicios"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_unidades">¿Que tipo de unidades tiene?</label>
          <Select2
            id="c_unidades"
            v-model="dataForm.caracteristicas.unidades"
            :options="unidades"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_patios">¿En que Estados tiene patios?</label>
          <Select2
            id="e_patios"
            v-model="dataForm.caracteristicas.patios_estado"
            :options="patios"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
          />
        </div>
      </div>

      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_ciudades">¿En que Ciudades tiene patios?</label>
          <Select2
            id="c_patios"
            v-model="dataForm.caracteristicas.patios_ciudades"
            :options="ciudades"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_patios">¿Que rutas busca?</label>
          <Select2
            id="rutas"
            v-model="dataForm.caracteristicas.rutas"
            :options="patios"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
          />
        </div>
      </div>

      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="c_clasificacion"
            >¿Maneja alguno de estos servicios?</label
          >
          <Select2
            id="c_clasificacion"
            v-model="dataForm.caracteristicas.clasificaciones"
            :options="clasificaciones"
            :settings="{
              multiple: true,
              placeholder: ':: Seleccionar :',
              closeOnSelect: false,
            }"
            @select="() => {}"
            @change="() => {}"
          />
        </div>
      </div>
      <div class="col-md-3 mt-5">
        <div class="form-group">
          <label for="clasificacion_otros">Otros servicios</label>
          <input
            type="text"
            class="form-control form-control-sm"
            id="cp"
            v-model="dataForm.caracteristicas.otros"
          />
        </div>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-md-12 mt-5"><hr /></div>
      <div class="col-md-3 mt-5">
        <button type="submit" id="btn_submit" class="btn btn-primary">
          <span class="indicator-label">GUARDAR</span>
          <span class="indicator-progress"
            >Guardando...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span
          ></span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, watch, watchEffect } from "vue";
import useDatos from "../composables/useDatos";
import Swal from "sweetalert2";
import Select2 from "vue3-select2-component";
export default {
  components: { Select2 },
  setup() {
    const { info, dataFormIni, setInfo, loading, catalogos } = useDatos();
    const dataForm = ref(dataFormIni);
    const tipos = ref([]);
    const ciudades = ref([]);
    const clasificaciones = ref([]);
    const patios = ref([]);
    const rutas = ref([]);
    const servicios = ref([]);
    const unidades = ref([]);

    const handleGuardar = async () => {
      const data = await setInfo(dataForm.value);
      if (data.ok) {
        Swal.fire("Guardado", "Se han guardado los cambios", "success");
      }
    };

    const cargarInfo = () => {
      Object.keys(dataForm.value).forEach((clave) => {
        dataForm.value[clave] = info.value[clave];
      });
    };

    if (info.value) {
      cargarInfo();
    }

    watch(
      () => info.value,
      () => {
        cargarInfo();
      }
    );

    watch(
      () => loading.value,
      () => {
        const btnLogin = document.querySelector("#btn_submit");
        if (loading.value) {
          btnLogin.setAttribute("data-kt-indicator", "on");
          btnLogin.disabled = true;
        } else {
          btnLogin.setAttribute("data-kt-indicator", "off");
          btnLogin.disabled = false;
        }
      }
    );

    watch(
      () => catalogos.value,
      () => {
        tipos.value = catalogos.value.tipos;
        ciudades.value = catalogos.value.ciudades;
        clasificaciones.value = catalogos.value.clasificaciones;
        patios.value = catalogos.value.patios;
        rutas.value = catalogos.value.rutas;
        servicios.value = catalogos.value.servicios;
        unidades.value = catalogos.value.unidades;
      }
    );

    return {
      dataForm,
      handleGuardar,
      info,
      tipos,

      ciudades,
      clasificaciones,
      patios,
      rutas,
      servicios,
      unidades,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
import {computed} from "vue"
import {useStore} from "vuex"

const useDatos = () => {
  const store = useStore()

  const getInfo = async () => {
    await store.dispatch("datos/getInfo")
  }

  const getCatalogos = async () => {
    await store.dispatch("datos/getCatalogos")
  }

  const setInfo = async (datos) => {
    return await store.dispatch("datos/setInfo", datos)
  }

  const setContacto = async (datos) => {
    return await store.dispatch("datos/setContacto", datos)
  }

  const setEliminarContacto = async (id) => {
    return await store.dispatch("datos/setEliminarContacto", id)
  }

  const setBancos = async (datos) => {
    return await store.dispatch("datos/setBancos", datos)
  }

  const setEliminarBancos = async (id) => {
    return await store.dispatch("datos/setEliminarBancos", id)
  }

  const dataFormIni = {
    razonSocial: "",
    nombre_comercial: "",
    nacionalidad: "",
    rfc: "",
    telefono: "",
    tax_id: "",
    domicilio: {
      calle: "",
      colonia: "",
      cp: "",
      estado: "",
      municipio: "",
    },
    representante: {
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      extension: "",
      celular: "",
    },
    caracteristicas: {
      tipo_id: null,
      servicios: [],
      unidades: [],
      patios_estado: [],
      patios_ciudades: [],
      rutas: [],
      clasificaciones: [],
      otros: "",
    },
  }

  const dataContactoVentasIni = {
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    extension: "",
    celular: "",
    tipo_id: 1,
    id: "",
  }

  const dataContactoOperacionesIni = {
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    extension: "",
    celular: "",
    tipo_id: 2,
    id: "",
  }

  const dataContactoAdministracionIni = {
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    extension: "",
    celular: "",
    tipo_id: 3,
    id: "",
  }

  const dataBancoIni = {
    tipo_id: 1,
    banco: "",
    beneficiario: "",
    direccion: "",
    cuenta: "",
    clabe: "",
    rfc: "",
    correo: "",
    swift: "",
    aba: "",
  }

  return {
    dataFormIni,
    dataContactoVentasIni,
    dataContactoOperacionesIni,
    dataContactoAdministracionIni,
    dataBancoIni,
    getInfo,
    setInfo,
    setContacto,
    setEliminarContacto,
    setBancos,
    setEliminarBancos,
    getCatalogos,
    info: computed(() => store.getters["datos/info"]),
    loading: computed(() => store.getters["datos/loading"]),
    ventas: computed(() => store.getters["datos/ventas"]),
    operaciones: computed(() => store.getters["datos/operaciones"]),
    administracion: computed(() => store.getters["datos/administracion"]),
    bancos: computed(() => store.getters["datos/bancos"]),
    referencias: computed(() => store.getters["datos/referencias"]),
    catalogos: computed(() => store.getters["datos/catalogos"]),
  }
}

export default useDatos

<template>
  <div class="row mt-5">
    <div class="col-md-12 mt-3">
      <hr />
      Cuentas Registradas (PESOS)
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Moneda</th>
            <th>Banco</th>
            <th>Dirección</th>
            <th>Beneficiario</th>
            <th>Cuenta</th>
            <th>Clabe</th>
            <th>R.F.C.</th>
            <th>Correo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="bancosMXN.length > 0">
            <tr v-for="banco in bancosMXN" :key="banco.id">
              <td>MXN</td>
              <td>{{ banco.banco }}</td>
              <td>{{ banco.direccion }}</td>
              <td>{{ banco.beneficiario }}</td>
              <td>{{ banco.cuenta }}</td>
              <td>{{ banco.clabe }}</td>
              <td>{{ banco.rfc }}</td>
              <td>{{ banco.correo }}</td>
              <td nowrap>
                <button
                  class="btn btn-primary btn-sm btn-table"
                  @click="
                    () => {
                      handleEditar(banco);
                    }
                  "
                >
                  <font-awesome-icon icon="edit" />
                </button>

                <button
                  class="btn btn-danger btn-sm btn-table mx-1"
                  type="button"
                  @click="
                    () => {
                      handleDelete(banco.id);
                    }
                  "
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">No se han cargado cuentas MXN</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="col-md-12 mt-3">
      <hr />
      Cuentas Registradas (USD)
      <table class="table table-hover table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Moneda</th>
            <th>Banco</th>
            <th>Dirección</th>
            <th>Beneficiario</th>
            <th>Cuenta</th>
            <th>SWIFT</th>
            <th>ABA</th>
            <th>Correo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="bancosUSD.length > 0">
            <tr v-for="banco in bancosUSD" :key="banco.id">
              <td>USD</td>
              <td>{{ banco.banco }}</td>
              <td>{{ banco.direccion }}</td>
              <td>{{ banco.beneficiario }}</td>
              <td>{{ banco.cuenta }}</td>
              <td>{{ banco.swift }}</td>
              <td>{{ banco.aba }}</td>
              <td>{{ banco.correo }}</td>
              <td nowrap>
                <button
                  class="btn btn-primary btn-sm btn-table"
                  @click="
                    () => {
                      handleEditar(banco);
                    }
                  "
                >
                  <font-awesome-icon icon="edit" />
                </button>

                <button
                  class="btn btn-danger btn-sm btn-table mx-1"
                  type="button"
                  @click="
                    () => {
                      handleDelete(banco.id);
                    }
                  "
                >
                  <font-awesome-icon icon="trash" />
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">No se han cargado cuentas USD</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="col-md-12 mt-3">
      <button class="btn btn-twitter btn-sm" @click="openFormBanco">
        Agregar cuenta
      </button>
    </div>
  </div>

  <b-modal
    title="Agregar Cuenta"
    id="formaBanco"
    ok-title="Guardar"
    cancel-title="Cerrar"
    ok-only
    v-model="formaBanco"
    no-close-on-esc="false"
    @ok="handleSaveCuenta"
  >
    <div class="row">
      <div class="col-md-12">
        <label for="tipo_id">Tipo de Moneda</label>
        <select
          name="tipo_id"
          id="tipo_id"
          v-model="dataForm.tipo_id"
          class="form-control form-control-sm"
        >
          <option value="1">MXN</option>
          <option value="2">USD</option>
        </select>
      </div>

      <div class="col-md-12">
        <label for="banco">Banco</label>
        <input
          type="text"
          name="banco"
          id="banco"
          v-model="dataForm.banco"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12">
        <label for="beneficiario">Beneficiario</label>
        <input
          type="text"
          name="beneficiario"
          id="beneficiario"
          v-model="dataForm.beneficiario"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12">
        <label for="cuenta">No. Cuenta</label>
        <input
          type="text"
          name="cuenta"
          id="cuenta"
          v-model="dataForm.cuenta"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12" v-if="!cuentaUSD">
        <label for="clabe">CLABE</label>
        <input
          type="text"
          name="clabe"
          id="clabe"
          v-model="dataForm.clabe"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12">
        <label for="correo">Correo</label>
        <input
          type="text"
          name="correo"
          id="correo"
          v-model="dataForm.correo"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12" v-if="!cuentaUSD">
        <label for="rfc">R.F.C.</label>
        <input
          type="text"
          name="rfc"
          id="rfc"
          v-model="dataForm.rfc"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12" v-if="cuentaUSD">
        <label for="swift">SWIFT</label>
        <input
          type="text"
          name="swift"
          id="swift"
          v-model="dataForm.swift"
          class="form-control form-control-sm"
        />
      </div>

      <div class="col-md-12" v-if="cuentaUSD">
        <label for="aba">ABA</label>
        <input
          type="text"
          name="aba"
          id="aba"
          v-model="dataForm.aba"
          class="form-control form-control-sm"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch, watchEffect } from "vue";
import useDatos from "../composables/useDatos";
import Swal from "sweetalert2";
export default {
  setup() {
    const formaBanco = ref(false);
    const { bancos, dataBancoIni, setBancos, setEliminarBancos } = useDatos();
    const dataForm = ref(dataBancoIni);
    const cuentaUSD = ref(false);
    const bancosMXN = ref(bancos.value.filter((item) => item.tipo_id == 1));
    const bancosUSD = ref(bancos.value.filter((item) => item.tipo_id == 2));

    const handleCloseForma = () => {};

    const handleSaveCuenta = async () => {
      const result = await setBancos(dataForm.value);
      if (result.ok) {
        Swal.fire(
          "Guardado",
          "Se ha guardado la información bancaria",
          "success"
        );
      }
    };

    const openFormBanco = () => {
      formaBanco.value = true;
    };

    const handleEditar = (item) => {
      formaBanco.value = true;
      dataForm.value = { ...dataForm.value, ...item };
    };

    const handleDelete = async (id) => {
      Swal.fire({
        title: "Confirmar",
        text: "Se eliminará la cuenta bancaria",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Eliminar!",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await setEliminarBancos(id);
          if (result.ok) {
            //Swal.fire("Eliminado!", "Se ha eliminado el contacto.", "success");
          }
        }
      });
    };

    watch(
      () => dataForm.value.tipo_id,
      () => {
        cuentaUSD.value = dataForm.value.tipo_id == 2;
      }
    );

    watch(
      () => bancos.value,
      () => {
        bancosMXN.value = bancos.value.filter((item) => item.tipo_id == 1);
        bancosUSD.value = bancos.value.filter((item) => item.tipo_id == 2);
      }
    );

    watchEffect(() => dataForm.value);

    return {
      cuentaUSD,
      formaBanco,
      dataForm,
      bancosMXN,
      bancosUSD,
      openFormBanco,
      handleEditar,
      handleDelete,
      handleCloseForma,
      handleSaveCuenta,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>